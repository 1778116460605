<template>
  <h-container isTable title="中心管理">
    <template #extra-header>
      <div class="flex-1 text-align-right">
        <el-button type="primary" @click="createTeam">创建小组</el-button>
      </div>
    </template>
    <div>
      <el-form ref="formRef" :model="model" :inline="true" label-width="auto">
        <el-form-item label="中心名称" prop="name">
          <el-select v-model="model.name" filterable placeholder="请选择中心名称" style="width: 240px">
            <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.name"/>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="model.status" placeholder="请选择状态" style="width: 240px">
            <h-dict dictKey="status_type"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="loading" :data="list" stripe style="width: 100%">
      <el-table-column prop="id" label="编号" header-align="center" align="center" width="100" />
      <el-table-column prop="name" header-align="center" align="center" label="标题" width="180" />
      <el-table-column prop="createtime" header-align="center" align="center" label="创建时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.createtime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" header-align="center" align="center" label="修改时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.updatetime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="status" header-align="center" align="center" label="状态">
        <template #default="scope">
          <el-tag v-if="scope.row.status == 'normal'" :type="scope.row.status == 'normal' ? 'success' : 'danger'"><h-dict mode="text" dictKey="status_type" v-model="scope.row.status"/></el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" header-align="center" fixed="right" width="260">
        <template #default="scope">
          <el-button type="primary" @click="toUpdatePage(scope.row)" link>修改</el-button>
          <el-button type="primary" @click="toAnnouncementPage(scope.row)" link>配置公告</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="h-pagination">
        <h-pagination @change="paginationChangeEvent" :current-page="page" :total="totalRow" />
      </div>
    </template>
  </h-container>
</template>

<script lang="ts" setup>
import {ref, onMounted} from "vue";
import {useRouter} from "vue-router";
import {useApp} from "@hview/hview-vue3/packages/hooks/use-app";
import {useTimeFormat} from "@hview/hview-vue3/packages";
import {getTeamListApi} from "@/api/team";
import {usePagination} from "@hview/hview-vue3/packages";
const {updatePage, getPagination, totalRow, update, reload, page} = usePagination();
const {timeFormat} = useTimeFormat();
const router = useRouter();

const formRef = ref();

const {loading} = useApp();
const list = ref([]);

/**
 * 提交到后台的数据
 */
const model = ref({
  name: "",
  status: ""
});

/**
 * @description 重置
 */
const resetForm = () => {
  formRef.value.resetFields();
  reload(load);
}

/**
 * @description 创建team
 */
const createTeam = () => {
  router.push("/team/create");
}

/**
 * @description 跳转到更新页面
 */
const toUpdatePage = (data: any) => {
  router.push(`/team/${data.id}`);
}

/**
 * @description 跳转到公告配置页面
 */
const toAnnouncementPage = (data: any) => {
  router.push(`/team/announcement?id=${data.id}`);
}

/**
 * @description 页码改变事件
 */
const paginationChangeEvent = (value: number) => {
  update(value, load);
}


/**
 * @description 加载数据
 */
const load = () => {
  loading.value = true;
  getTeamListApi({...model.value, ...getPagination()}).then((res: any) => {
    const data = res.data;
    updatePage(data);
    list.value = data.data;
  }).finally(() => {
    loading.value = false;
  });
}



onMounted(() => {
  load();
});
</script>

<style lang="scss">

</style>