<template>
  <h-container v-loading="loading" isTable isBack :title="uiModel.title">
    <!-- 基础信息  -->
    <base-info ref="baseInfoRef"/>

    <!-- 热门工具  -->
    <hot-tools ref="hotToolsRef"/>

    <!-- 主功能区域  -->
    <functional-zone ref="functionalZoneRef"/>

    <!-- 关联项目 -->
    <correlation-project ref="correlationProjectRef"/>

    <!-- 关联问卷 -->
    <correlation-test ref="correlationTestRef"/>



    <!-- 分享信息  -->
    <share-info ref="shareInfoRef"/>
    <template #footer>
      <div class="h-button-wrapper">
        <template v-if="model.id">
          <el-button type="success" size="large" @click="update('STAGING')" plain>暂存</el-button>
          <el-button type="primary" size="large" @click="update('SAVE')">修改</el-button>
        </template>
        <template v-else>
          <el-button type="success" size="large" @click="submit('STAGING')" plain>暂存</el-button>
          <el-button type="primary" size="large" @click="submit('SAVE')">保存</el-button>
        </template>

      </div>
    </template>
  </h-container>
</template>

<script lang="ts" setup>
  import {onMounted, ref} from "vue";
  import {useRoute, useRouter} from "vue-router";
  import {FunctionalZone, BaseInfo, HotTools, ShareInfo, CorrelationProject, CorrelationTest} from "@/components/views/team";
  import {addTeamApi, getTeamInfoApi, updateTeamApi} from "@/api/team";
  import {useApp} from "@hview/hview-vue3/packages/hooks/use-app";
  import {Notice} from "@hview/hview-vue3/packages";
  const {loading} = useApp();
  const route = useRoute();
  const router = useRouter();
  const uiModel: any = ref({
    type: ""
  });

  const model = ref({
    id: ""
  });



  /**
   * 基本信息ref
   */
  const baseInfoRef = ref();

  /**
   * 热门工具ref
   */
  const hotToolsRef = ref();

  /**
   * 主功能区ref
   */
  const functionalZoneRef = ref();

  /**
   * 关联项目
   */
  const correlationProjectRef = ref();

  /**
   * 关联问卷
   */
  const correlationTestRef = ref();

  /**
   * 分享功能ref
   */
  const shareInfoRef = ref();

  const load = () => {
    let id = ''
    if (route && route.params && route.params.id) {
      id = route.params.id
    }
    loading.value = true;
    getTeamInfoApi({teamId: id}).then((res: any) => {
      const {data} = res;
      model.value.id = data.id;
      setBaseInfoData(data);
      setToolsData(data);
      setShareInfoData(data);
      setFunctionalData(data);
      setCorrelationProjectData(data);
      setCorrelationTestData(data);
    }).finally(() => {
      loading.value = false;
    });
  }

  /**
   * @description 设置工具数据
   */
  const setToolsData = (data: any) => {
    const {header_button_json} = data;
    if (header_button_json) {
      const r = header_button_json.replace(/&quot;/g,"\"");
      hotToolsRef.value.setData(JSON.parse(r));
    }
  }


  /**
   * @description 设置分享信息数据
   */
  const setShareInfoData = (data: any) => {
    const {share_info_json} = data;
    if (share_info_json) {
      const r = share_info_json.replace(/&quot;/g,"\"");
      shareInfoRef.value.setData(JSON.parse(r));
    }
  }

  /**
   * @description 设置功能区数据
   */
  const setFunctionalData = (data: any) => {
    const {team_page_button_json} = data;
    if (team_page_button_json) {
      functionalZoneRef.value.setData(JSON.parse(team_page_button_json));
    }

  }

  /**
   * @description 设置关联项目
   */
  const setCorrelationProjectData = (data: any) => {
    const {kh_project_id} = data;
    correlationProjectRef.value.setData(kh_project_id);
  }
  /**
   * @description 设置关联问卷
   */
  const setCorrelationTestData = (data: any) => {
    const {test_id} = data;
    correlationTestRef.value.setData(test_id);
  }

  /**
   * @description 设置基础信息
   */
  const setBaseInfoData = (data: any) => {
    const {images_list, intro, name, status, foot_desc, is_defalut_add} = data;
    const r = {
      images: images_list[0] || "",
      intro,
      name,
      status,
      footerList: [],
      is_defalut_add
    };
    if (foot_desc) {
      r.footerList = foot_desc.split("|||")
    }
    baseInfoRef.value.setData(r);
  }

  const update = async (type: string) => {
    try {
      // 先验证表单
      await baseInfoRef.value.validate();
      await hotToolsRef.value.validate();
      await functionalZoneRef.value.validate();
      await shareInfoRef.value.validate();
      await correlationProjectRef.value.validate();
      await correlationTestRef.value.validate();
      loading.value = true;
      const data = getFormData();
      updateTeamApi(data).then(() => {
        Notice.success("修改成功");
        if (type == "SAVE") {
          router.go(-1);
        }
      }).finally(() => {
        loading.value = false;
      });
    } catch (e: any) {
      console.info(e)
    }
  }

  const submit = async (type: string) => {
    try {
      // 先验证表单
      console.log(baseInfoRef.value, hotToolsRef.value, functionalZoneRef.value, shareInfoRef.value, correlationProjectRef.value, correlationTestRef.value)
      await baseInfoRef.value.validate();
      await hotToolsRef.value.validate();
      await functionalZoneRef.value.validate();
      await shareInfoRef.value.validate();
      await correlationProjectRef.value.validate();
      await correlationTestRef.value.validate();
      loading.value = true;
      const data = getFormData();
      addTeam(data, (() => {
        if (type == "SAVE") {
          router.go(-1);
        }
      }));
    } catch (e: any) {
      console.info(e)
    }
  }

  /**
   * @description 获取表单data
   */
  const getFormData = () => {
    const baseInfo = baseInfoRef.value.getFormData();
    const hotTools = hotToolsRef.value.getFormData();
    const functionalZone = functionalZoneRef.value.getFormData();
    const shareInfo = shareInfoRef.value.getFormData();
    const correlationProject = correlationProjectRef.value.getFormData();
    const correlationTest = correlationTestRef.value.getFormData();
    let data: any = {};
    Object.assign(data, baseInfo);
    Object.assign(data, hotTools);
    Object.assign(data, shareInfo);
    Object.assign(data, functionalZone);
    Object.assign(data, correlationProject);
    Object.assign(data, correlationTest);
    if (model.value.id) {
      data["id"] = model.value.id;
    }
    return data;
  }

  /**
   * @description 添加小组
   */
  const addTeam = (data: any, fn: (r: any) => void) => {
    addTeamApi(data).then((res: any) => {
      Notice.success("操作成功");
      fn(res);
    }).finally(() => {
      loading.value = false;
    });
  }

  /**
   * @description 初始化
   */
  const init = () => {
    var id = ''
    if (route && route.params && route.params.id) {
      id = route.params.id
    } else {
      id = "create"
    }
    if (id == "create") {
      uiModel.value.type = id;
      uiModel.value.title = "创建小组";
    } else {
      load();
    }
  }

  onMounted(() => {
    init();
  });
</script>

<style scoped>

</style>