import { RouteRecordRaw } from 'vue-router'

const patient: Array<RouteRecordRaw> = [{
    path: '/patient',
    meta: {
        title: "患者管理"
    },
    component: () => import("@/views/patient/team.vue")
}, {
    path: '/patient/list',
    meta: {
        title: "成员列表"
    },
    component: () => import("@/views/patient/list.vue")
}];
export default patient;