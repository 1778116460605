/**
 * @description 路由集合
 * @version v1.0
 * @author zhaxianhe
 */
import { RouteRecordRaw } from "vue-router";
//扫描routes文件夹中的ts文件
const modulesFiles = (require as any).context("../routes", true, /\.ts$/);
//定义路由数组
const routes: Array<RouteRecordRaw> = [];
//循环文件path
modulesFiles.keys().forEach((modulePath: string) => {
    //排除index.ts文件
    if (modulePath.indexOf("index.ts") === -1) {
        routes.push(...modulesFiles(modulePath).default);
    }
});

export default routes;