<template>
  <h-container isBack :title="uiModel.title">
    <template #extra-header>
      <div class="flex-1 text-align-right">
        <el-button @click="t" type="warning" plain><i class="iconfont icon-yingyong m-r-4" style="font-size: 12px;"></i>商品库</el-button>
        <el-button :loading="btnLoading && saveType == 'zc'" type="primary" @click="save('zc')" plain>暂存</el-button>
        <el-button :loading="btnLoading && saveType == 'bc'" type="primary" @click="save('bc')">保存并退出</el-button>
      </div>
    </template>
    <div v-loading="loading">
      <h-editor ref="hEditorRef" v-model="model.content_fmt"></h-editor>
    </div>
  </h-container>
</template>

<script lang="ts" setup>
  import { ElMessage } from 'element-plus';
  import {ref} from "vue";
  import {useRoute, useRouter} from "vue-router";
  import {HEditor} from "@hview/hview-vue3";
  import {getArticleInfoApi, updateArticleApi} from "@/api/doctor";
  import {useApp} from "@hview/hview-vue3/packages/hooks";
  const {loading} = useApp();
  const btnLoading = ref<boolean>(false);
  const route = useRoute();
  const router = useRouter();

  const hEditorRef = ref();

  const t = () => {
    hEditorRef.value.getEditorRef().restoreSelection();
    const selection = hEditorRef.value.getEditorRef().selection;
    console.info(selection);
  }

  // 当前保存的类型
  const saveType: string = ref("");

  const uiModel = ref({
    title: ""
  });

  /**
   * 提交到后台的数据
   */
  const model = ref({
    id: "",
    content_fmt: ""
  });
  // 路由获取参数并设置文章id
  model.value.id = route.params.id;

  /**
   * 初始化加载数据
   */
  const load = () => {
    loading.value = true;
    getArticleInfoApi({id: model.value.id}).then((res: any) => {
      const data = res.data;
      model.value.content_fmt = data.content_fmt;
      uiModel.value.title = data.title;
    }).finally(() => {
      loading.value = false;
    })
  }
  load();

  const save = (type: string) => {
    saveType.value = type;
    btnLoading.value = true;
    updateArticleApi(model.value).then(() => {
      ElMessage({
        message: "保存成功",
        type: 'success',
        plain: true,
      });
      if (type == "bc") {
        setTimeout(() => {
          router.back();
        }, 800);
      }
    }).finally(() => {
      btnLoading.value = false;
    });
  }

</script>

<style scoped>

</style>