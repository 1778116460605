import { RouteRecordRaw } from 'vue-router';
import {HRouterView} from "@hview/hview-vue3/packages";

const coupon: Array<RouteRecordRaw> = [{
    path: '/coupon',
    component: HRouterView,
    children: [{
        path: "",
        meta: {
            title: "优惠券管理"
        },
        component: () => import("@/views/coupon/list.vue")
    }, {
        path: "record",
        meta: {
            title: "领取记录"
        },
        component: () => import("@/views/coupon/record.vue")
    }, {
        path: "edit",
        meta: {
            title: ""
        },
        component: () => import("@/views/coupon/edit.vue")
    }]
}];
export default coupon;