<!--
  @description: 热门工具
-->
<template>
  <div class="hot-tools">
    <div class="h-item-title">
      <span>热门工具</span>
      <div v-if="uiModel.isConfigTools == '1'">
        <el-button type="primary" :icon="Tools" plain @click="addTools">添加工具</el-button>
      </div>
    </div>
    <div>
      <el-form ref="formRef" :model="uiModel" label-width="auto">
        <el-form-item label="是否配置" label-width="130">
          <el-radio-group v-model="uiModel.isConfigTools">
            <el-radio value="0" size="large">不需要</el-radio>
            <el-radio value="1" size="large">需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="uiModel.isConfigTools == '1'">
            <el-table :data="uiModel.toolList" stripe style="width: 100%">
              <el-table-column header-align="center" align="center" type="index" label="序号" width="80" />
              <el-table-column header-align="center" align="center" prop="title" label="标题" width="280">
                <template #default="scope">
                  <el-form-item :prop="'toolList[' + scope.$index + '].title'" :rules="rules.title">
                    <el-input class="w-200 m-t-15" v-model="scope.row.title" placeholder="请输入标题"/>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column header-align="center" align="center" prop="path" label="跳转路径" width="300">
                <template #default="scope">
                  <el-form-item :prop="'toolList[' + scope.$index + '].path'" :rules="rules.path">
                    <el-input class="w-300 m-t-15" v-model="scope.row.path" placeholder="请输入跳转路径"/>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column header-align="center" align="center" prop="icon" label="图标">
                <template #default="scope">
                  <el-form-item class="m-t-15" :prop="'toolList[' + scope.$index + '].icon'" :rules="rules.icon">
                    <h-upload v-model="scope.row.icon">
                      <template #default>
                        <el-button :icon="UploadFilled">上传图标</el-button>
                      </template>
                      <template #file="scope">
                        <img :src="scope.file.fullurl" class="w-50 h-50"/>
                      </template>
                    </h-upload>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column header-align="center" align="center" fixed="right" width="130" label="操作">
                <template #default="scope">
                  <el-popconfirm title="确认删除吗?" @confirm="deleteRow(scope)">
                    <template #reference>
                  <span class="m-l-10">
                    <el-button type="primary" link>删除</el-button>
                  </span>
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
              <template #empty>
                <el-empty description="还没有配置工具">
                  <el-button type="primary" plain @click="addTools">立即添加</el-button>
                </el-empty>
              </template>
            </el-table>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts" setup name="HotTools">
  import {ref, defineExpose} from "vue";
  import { Tools, UploadFilled } from '@element-plus/icons-vue';
  const uiModel: any = ref({
    // 是否配置工具
    isConfigTools: "0",
    toolList: []
  });

  const formRef = ref();

  /**
   * 校验规则
   */
  const rules = ref({
    title: {
      required: true,
      message: "请输入标题",
      trigger: ["blur", "change"]
    },
    path: {
      required: true,
      message: "请输入路径",
      trigger: ["blur", "change"]
    },
    icon: {
      required: true,
      message: "请上传图标",
      trigger: ["change"]
    }
  });

  /**
   * @description 添加工具
   */
  const addTools = () => {
    uiModel.value.toolList.push({
      title: "",
      path: "",
      icon: ""
    });
  }

  /**
   * @description 删除行数据
   * @param data
   */
  const deleteRow = (data: any) => {
    uiModel.value.toolList.splice(data.$index, 1);
  }

  /**
   * @description 表单验证
   */
  const validate = () => {
    return new Promise((resolve, reject) => {
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          resolve(valid);
        } else {
          reject(valid);
        }
      });
    })
  };


  /**
   * @description 获取表单数据
   */
  const getFormData = () => {
    if (uiModel.isConfigTools == "0") {
      return {};
    }
    return {header_button_json: JSON.stringify(uiModel.value.toolList)};
  }

  /**
   * @description 设置数据
   * @param data
   */
  const setData = (data: any) => {
    if (data.length > 0) {
      uiModel.value.isConfigTools = "1";
      uiModel.value.toolList = data;
    }
  }


  defineExpose({
    validate,
    getFormData,
    setData
  });
</script>

<style scoped>

</style>