/**
 * @description 成人全营养项目接口
 */

import Http, {RequestParams} from "@hview/hview-http";

/**
 * @description 获取项目列表
 */
export const getProjectListApi = () => Http.post("/api/kh/project/getProjectList");

/**
 * @description 创建项目
 */
export const createProjectApi = (params: RequestParams) => Http.post("/api/kh/project/createProject", params);

/**
 * @description 获取项目信息
 * @param params
 */
export const getProjectInfoApi = (params: RequestParams) => Http.post("/api/kh/project/projectInfo", params);

/**
 * @description 获取审核列表
 * @param params
 */
export const getCertificationListApi = (params: RequestParams) => Http.post("/api/kh/Certification/getCertificationList", params);

/**
 * 通过id获取认证详情
 * @param params
 */
export const getCertificationInfoApi = (params: RequestParams) => Http.post("/api/kh/Certification/getCertificationInfo", params);

/**
 * @description 通过项目ID获取team
 * @param params
 */
export const getTeamListByProjectApi = (params: RequestParams) => Http.post("/api/kh/project/getTeamListByProject", params);

/**
 * @description 审核
 * @param params
 */
export const certificationApproveApi = (params: RequestParams) => Http.post("/api/kh/Certification/CertificationApprove", params);

/**
 * @description 获取审批列表
 * @return {Promise<unknown>}
 */
export const getApproveListApi = (params: RequestParams) => Http.post("/api/kh/user/getApproveList", params);


/**
 * @description 获取审批详情
 * @param params
 * @return {Promise<unknown>}
 */
export const getTeamAddFormInfoApi = (params: RequestParams) => Http.post("/api/kh/user/getTeamAddFormInfo", params);

/**
 * @description 审核患者
 * @param params
 */
export const teamAddFormApproveApi = (params: RequestParams) => Http.post("/api/kh/user/teamAddFormApprove", params);

/**
 * @description 查询公告列表
 * @param params
 */
export const getPublicNoticeListApi = (params: RequestParams) => Http.post("/api/kh/team/getPublicNoticeList", params);

/**
 * @description 保存公告
 * @param params
 */
export const savePublicNoticeApi = (params: RequestParams) => Http.post("/api/kh/team/savePublicNotice", params);

/**
 * @description 查询公告
 * @param params
 */
export const getPublicNoticeInfoApi = (params: RequestParams) => Http.post("/api/kh/team/getPublicNoticeInfo", params);

/**
 * @description 查询优惠券列表
 * @param params
 */
export const getCouponsListApi = (params: RequestParams) => Http.post("/api/kh/Coupons/getCouponsList", params);

/**
 * @description 获取用户列表
 * @param params
 */
export const getUserListApi = (params: RequestParams) => Http.post("/api/kh/user/getUserList", params);

/**
 * @description 获取商品列表
 * @param params
 */
export const getGoodsListApi = (params: RequestParams) => Http.post("/api/kh/Coupons/getGoodsList", params);

/**
 * @description 创建优惠券
 * @param params
 */
export const adminCreateCouponsApi = (params: RequestParams) => Http.post("/api/kh/Coupons/adminCreateCoupons", params);

/**
 * @description 获取领取记录
 * @param params
 */
export const adminGetUserReceivecouponsListApi = (params: RequestParams) => Http.post("/api/kh/Coupons/adminGetUserReceivecouponsList", params);

/**
 * @description 管理员领取优惠券
 * @param params
 */
export const adminDistributeCouponsApi = (params: RequestParams) => Http.post("/api/kh/Coupons/adminDistributeCoupons", params);

/**
 * @description 通过id查询优惠券
 * @param params
 */
export const getCouponsInfoApi = (params: RequestParams) => Http.post("/api/kh/Coupons/getCouponsInfo", params);

/**
 * @description 修改优惠券
 * @param params
 */
export const adminUpdateCouponsApi = (params: RequestParams) => Http.post("/api/kh/Coupons/adminUpdateCoupons", params);

/**
 * @description 发放积分
 * @param params
 */
export const sendScore = (params: RequestParams) => Http.post("/api/kh/Coupons/sendscore", params);
export const getshenheFormInfoApi = (params: RequestParams) => Http.post("/api/kh/user/getshenheform", params);
export const saveshenheformApi = (params: RequestParams) => Http.post("/api/kh/user/saveshenheform", params);
export const getruzuformApi = (params: RequestParams) => Http.post("/api/kh/user/getruzuform", params);
export const mark_resultApi = (params: RequestParams) => Http.post("/addons/mental/testlog/mark_result", params);
