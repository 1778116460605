export function useTimeFormat () {
    const timeFrom = (dateTime: any = null, format: any = 'yyyy-mm-dd') => {
        // 如果为null,则格式化当前时间
        if (!dateTime) dateTime = Number(new Date());
        // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
        if (dateTime.toString().length == 10) dateTime *= 1000;
        let timestamp = + new Date(Number(dateTime));

        let timer = (Number(new Date()) - timestamp) / 1000;
        // 如果小于5分钟,则返回"刚刚",其他以此类推
        let tips = '';
        switch (true) {
            case timer < 300:
                tips = '刚刚';
                break;
            case timer >= 300 && timer < 3600:
                tips = parseInt((timer / 60) + '') + '分钟前';
                break;
            case timer >= 3600 && timer < 86400:
                tips = parseInt((timer / 3600) + '') + '小时前';
                break;
            case timer >= 86400 && timer < 2592000:
                tips = parseInt((timer / 86400) + '') + '天前';
                break;
            default:
                // 如果format为false，则无论什么时间戳，都显示xx之前
                if(format == false) {
                    if(timer >= 2592000 && timer < 365 * 86400) {
                        tips = parseInt((timer / (86400 * 30)) + '') + '个月前';
                    } else {
                        tips = parseInt((timer / (86400 * 365)) + '') + '年前';
                    }
                } else {
                    tips = timeFormat(timestamp, format);
                }
        }
        return tips;
    }


    /**
     * @description 日期格式化
     * @param dateTime
     * @param fmt
     */
    const timeFormat = (dateTime: any = null, fmt = 'yyyy-mm-dd') => {
        // 如果为null,则格式化当前时间
        if (!dateTime) dateTime = Number(new Date());
        // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
        if (dateTime.toString().length == 10) dateTime *= 1000;
        let date = new Date(dateTime);
        let ret;
        let opt: any = {
            "y+": date.getFullYear().toString(), // 年
            "m+": (date.getMonth() + 1).toString(), // 月
            "d+": date.getDate().toString(), // 日
            "h+": date.getHours().toString(), // 时
            "M+": date.getMinutes().toString(), // 分
            "s+": date.getSeconds().toString() // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
    }

    return {
        timeFormat,
        timeFrom
    }
}