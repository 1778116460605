<!--
  @description: 创建/修改——基础信息
  @version: zhaxianhe
-->
<template>
  <div>
    <div class="h-item-title">基础信息</div>
    <div class="base-info">
      <el-form ref="formRef" :rules="rules" :model="model" :label-width="130">
        <el-form-item label="小组名称" prop="name">
          <el-input v-model="model.name" placeholder="请输入小组名称"/>
        </el-form-item>
        <el-form-item label="小组logo" prop="images">
          <h-upload v-model="model.images">
            <template #default>
              <el-button :icon="UploadFilled">上传小组图片</el-button>
            </template>
            <template #file="scope">
              <img :src="scope.file.fullurl" class="w-50 h-50"/>
            </template>
          </h-upload>
        </el-form-item>
        <el-form-item label="是否需要底部描述">
          <el-radio-group v-model="uiModel.isConfigFooter">
            <el-radio value="0" size="large">不需要</el-radio>
            <el-radio value="1" size="large">需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="uiModel.isConfigFooter == '1'">
          <el-form-item v-for="(item, index) in model.footerList" :rules="footerRules" :key="index" :prop="'footerList[' + index + ']'">
            <div class="flex align-items-center">
              <el-input placeholder="请输入描述" v-model="model.footerList[index]"/>
              <el-button class="m-l-20" :icon="Plus" @click="addFooter" type="primary">添加</el-button>
              <el-popconfirm title="确认删除吗?" @confirm="removeFooter(index)">
                <template #reference>
                  <span class="m-l-10">
                    <el-button type="danger" v-if="index > 0" :icon="Delete">删除</el-button>
                  </span>
                </template>
              </el-popconfirm>
            </div>
          </el-form-item>
        </template>
        <el-form-item label="简介" prop="intro">
          <el-input :rows="5" v-model="model.intro" type="textarea" placeholder="请输入小组简介（非必填）"/>
        </el-form-item>
        <el-form-item label="是否启用" prop="status">
          <el-radio-group v-model="model.status">
            <h-dict dictKey="status_type"/>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="默认中心" prop="is_defalut_add">
          <el-checkbox :true-value="1" :false-value="0" v-model="model.is_defalut_add">设为默认</el-checkbox>
        </el-form-item>

      </el-form>
    </div>
  </div>
</template>

<script lang="ts" setup name="BaseInfo">
  import {ref, defineExpose, watch} from "vue";
  import { Delete, Plus, UploadFilled } from '@element-plus/icons-vue';

  const formRef = ref();
  const uiModel = ref({
    // 是否配置页脚
    isConfigFooter: "0"
  });

  watch(() => uiModel.value.isConfigFooter, (value) => {
    if (value == "1" && model.value.footerList.length == 0) {
      addFooter();
    }
  });

  /**
   * 表单数据
   */
  const model = ref({
    name: "",
    images: "",
    intro: "",
    status: "normal",
    is_defalut_add: "0",
    footerList: [""]
  });

  /**
   * @description footer 校验规则
   */
  const footerRules = ref(
    {
      required: true,
      message: "请输入描述",
      trigger: ["blur", "change"],
    });

  /**
   * 校验规则
   */
  const rules = ref({
    name: [{
      required: true, message: "请输入小组名称", trigger: ["blur", "change"]
    }],
    status: [{
      required: true, message: "请输入状态", trigger: ["blur", "change"]
    }],
    images: [{
      required: true, message: "请上传小组图片", trigger: ["change"]
    }]
  });

  /**
   * @description 添加
   */
  const addFooter = () => {
    model.value.footerList.push("");
  }

  /**
   * @description 删除footer
   */
  const removeFooter = (index) => {
    model.value.footerList.splice(index, 1);
  }

  /**
   * @description 表单验证
   */
  const validate = () => {
    return new Promise((resolve, reject) => {
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          resolve(valid);
        } else {
          reject(valid);
        }
      })
    })
  }

  /**
   * @description 获取表单数据
   */
  const getFormData = () => {
    let data = {
      name: model.value.name,
      images: model.value.images,
      intro: model.value.intro,
      status: model.value.status,
      is_defalut_add: model.value.is_defalut_add,
      foot_desc: ""
    };
    // 判断是否配置页脚
    if (uiModel.value.isConfigFooter == "1") {
      data.foot_desc = model.value.footerList.join("|||");
    }
    return data;
  }

  /**
   * @description 设置数据
   */
  const setData = (data: any) => {
    model.value.name = data.name;
    model.value.images = data.images;
    model.value.intro = data.intro;
    model.value.status = data.status;
    model.value.is_defalut_add = data.is_defalut_add;
    model.value.footerList = data.footerList;
    if (model.value.footerList.length > 0) {
      uiModel.value.isConfigFooter = "1";
    }
  }

  defineExpose({
    validate,
    getFormData,
    setData
  });
</script>

<style scoped>

</style>