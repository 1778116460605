import {ref} from "vue";
export function usePagination () {
    // 当前页码
    const page = ref(1);
    // 每页数据条数
    const count = ref(10);
    // 总数据条数
    const totalRow = ref(0);

    const currentPageDataSize = ref(0)

    const lastPage = ref(1);

    /**
     * @description 更新page
     * @param data
     */
    const updatePage = (data: any) => {
        // 设置总条数
        totalRow.value = data.total;
        // 得出当前页的数据条数,主要是为了删除的时候方便，如果删除的那条正好是当前页的最后一条，则page要进行--
        currentPageDataSize.value = data.data.length;
        lastPage.value = data.last_page;
    }

    /**
     * @description 纠正页码
     */
    const correctPage = () => {
        if (currentPageDataSize.value == 1 && page.value > 1) {
            page.value--;
        }
    }

    /**
     * @description 更新列表
     * @param pageNum
     * @param loadFn
     */
    const update = (pageNum: number, loadFn: any) => {
        page.value = pageNum;
        loadFn();
    }

    /**
     * @description 获取分页数据
     */
    const getPagination = () => {
        return {
            page: page.value,
            count: count.value
        }
    }

    /**
     * @description 重置分页
     * @param loadFn
     */
    const reload = (loadFn?: any) => {
        page.value = 1;
        if (loadFn) {
            loadFn();
        }
    }


    return {
        updatePage,
        totalRow,
        getPagination,
        update,
        reload,
        page,
        count,
        correctPage
    }

}