<!--
  @description: 文章列表
  @author: zhaxianhe
  @version v1.0
-->
<template>
  <h-container isTable title="文章管理">
    <template #extra-header>
      <div class="flex-1 text-align-right">
        <el-button type="primary" @click="createArticleEvent">创建文章</el-button>
      </div>
    </template>
    <div>
      <el-form ref="formRef" :model="model" :inline="true" label-width="auto">
        <el-form-item label="文章标题" prop="title">
          <el-input v-model="model.title" placeholder="请输入文章标题" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="model.status" placeholder="请选择状态" style="width: 240px">
            <el-option label="启用" value="normal"/>
            <el-option label="禁用" value="hidden"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="reload(load)">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="uiModel.loading" :data="uiModel.list" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" header-align="center" align="center" width="100" />
      <el-table-column prop="title" label="标题" width="180" />
      <el-table-column prop="createtime" label="创建时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.createtime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.updatetime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template #default="scope">
          <el-tag v-if="scope.row.status == 'normal'" type="success">启用</el-tag>
          <el-tag v-if="scope.row.status == 'hidden'" type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" header-align="center" fixed="right" width="260">
        <template #default="scope">
          <div class="flex align-items-center">
            <el-button type="primary" link @click="preview(scope.row)">查看</el-button>
            <el-button type="primary" link @click="updateEvent(scope.row)">修改</el-button>
            <el-popconfirm @confirm="deleteConfirmEvent(scope.row)" title="删除后不可恢复，是否继续?" cancel-button-text="我再想想" confirm-button-text="确定" width="230">
              <template #reference>
                <el-button type="primary" link>删除</el-button>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- article 弹窗 -->
    <article-dialog ref="articleDialogRef" @change="load"/>
    <preview-article-dialog ref="previewArticleDialogRef"/>
    <template #footer>
      <div class="h-pagination">
        <h-pagination @change="paginationChangeEvent" :current-page="page" :total="totalRow" />
      </div>
    </template>
  </h-container>
</template>

<script lang="ts" setup>
  import ArticleDialog from "@/components/views/article/article-dialog.vue";
  import PreviewArticleDialog from "@/components/views/article/preview-article-dialog.vue";
  import {reactive, ref} from "vue";
  import {Notice, useTimeFormat} from "@hview/hview-vue3/packages";
  import {deleteArticleApi, getArticleListApi} from "@/api/doctor";
  import {usePagination} from "@hview/hview-vue3/packages";
  const {timeFormat} = useTimeFormat();
  // 使用分页hooks
  const {updatePage, getPagination, totalRow, update, reload, page, correctPage} = usePagination();
  const articleDialogRef = ref();
  const previewArticleDialogRef = ref();
  /**
   * 接口请求需要的数据
   */
  const model = reactive({
    id: "", // 文章id
    title: "", // 文章标题
    status: "", // 状态
    name: "" // 用户昵称或真实姓名
  });

  /**
   * 页面model
   */
  const uiModel = reactive({
    list: [],
    loading: false
  });

  /**
   * 表单ref
   */
  const formRef = ref(null);

  /**
   * @description 页码改变事件
   */
  const paginationChangeEvent = (value: number) => {
    update(value, load);
  }

  /**
   * @description 重置表单
   */
  const resetForm = () => {
    formRef.value.resetFields();
    reload(load);
  }

  /**
   * @description 删除事件
   * @param data
   */
  const deleteConfirmEvent = (data: any) => {
    deleteArticleApi({id: data.id}).then(() => {
      Notice.success("删除成功");
      correctPage();
      load();
    });
  }

  /**
   * @description 修改
   */
  const updateEvent = (data: any) => {
    articleDialogRef.value.update(data);
  }

  /**
   * @description 创建文章
   */
  const createArticleEvent = () => {
    articleDialogRef.value.create();
  }

  /**
   * @description 预览
   */
  const preview = (data: any) => {
    previewArticleDialogRef.value.preview(data);
  }

  /**
   * @description 初始化加载数据
   */
  const load = () => {
    uiModel.loading = true;
    getArticleListApi({...model,...getPagination()}).then((res: any) => {
      const data = res.data;
      updatePage(data);
      uiModel.list = data.data;
    }).finally(() => {
      uiModel.loading = false;
    });
  }

  load();
</script>
