<!--
  @description: 关联项目
-->
<template>
  <div>
    <div class="h-item-title">关联项目</div>
    <el-form ref="formRef" :model="model" :rules="rules">
      <el-form-item label="关联项目" prop="kh_project_id">
        <el-select placeholder="请选择项目" v-model="model.kh_project_id">
          <el-option :label="item.project_name" :value="item.id" v-for="(item, index) in list" :key="index"/>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="ts" setup name="CorrelationProject">
  import {ref, defineExpose, onMounted} from "vue";
  import {getProjectListApi} from "@/api/kh";

  const formRef = ref();
  const model = ref({
    kh_project_id: ""
  });

  const list = ref([]);

  const rules = ref({
    kh_project_id: {
      required: true,
      message: "请选择项目",
      trigger: ["blur", "change"]
    }});

  /**
   * @description 加载数据
   */
  const load = () => {
    getProjectListApi().then((res: any) => {
      const data = res.data;
      list.value = data;
    });
  }

  const setData = (id: any) => {
    model.value.kh_project_id = id;
  }

  const getFormData = () => {
    return model.value;
  }


  /**
   * @description 表单校验
   */
  const validate = () => {
    return new Promise((resolve, reject) => {
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          resolve(valid);
        } else {
          reject(valid);
        }
      })
    });
  }


  onMounted(() => {
    load();
  });

  defineExpose({
    validate,
    setData,
    getFormData
  });
</script>

<style lang="scss">

</style>