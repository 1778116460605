"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var team = [{
        path: '/team',
        meta: {
            title: "小组列表"
        },
        component: function () { return Promise.resolve().then(function () { return require("@/views/team/list.vue"); }); }
    }, {
        path: '/team/:id',
        meta: {
            title: "小组管理",
            // activeMenu: "/team"
        },
        component: function () { return Promise.resolve().then(function () { return require("@/views/team/manage.vue"); }); }
    }, {
        path: '/team/announcement',
        meta: {
            title: "小组公告管理",
            // activeMenu: "/team"
        },
        component: function () { return Promise.resolve().then(function () { return require("@/views/team/announcement/announcement-list.vue"); }); }
    }, {
        path: '/team/announcement/:id',
        meta: {
            title: "小组公告管理",
            // activeMenu: "/team"
        },
        component: function () { return Promise.resolve().then(function () { return require("@/views/team/announcement/announcement.vue"); }); }
    }];
exports.default = team;
