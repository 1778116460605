<!--
  @description: 公告配置
-->
<template>
  <h-container isBack isTable :title="model.id ? '修改公告' : '创建公告'">
    <div>
      <el-form ref="formRef" :rules="rules" :model="model" label-width="100">
        <el-form-item label="公告标题" prop="title">
          <el-input v-model="model.title"/>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="model.status">
            <h-dict dictKey="status_type"/>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="h-item-title">公告内容</div>
    <div>
      <div v-loading="loading">
        <h-editor ref="hEditorRef" v-model="model.content"></h-editor>
      </div>
    </div>
    <template #footer>
      <div class="h-button-wrapper">
        <el-button type="primary" :loading="loading" size="large" @click="submit">保存</el-button>
      </div>
    </template>
  </h-container>
</template>

<script lang="ts" setup>
import {ref, onMounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import {HEditor} from "@hview/hview-vue3";
import {useApp} from "@hview/hview-vue3/packages/hooks/use-app";
import {getPublicNoticeInfoApi, savePublicNoticeApi} from "@/api/kh";
import {Notice} from "@hview/hview-vue3/packages";
const {loading} = useApp();
const router = useRouter();
const route = useRoute();

const formRef = ref();
const model = ref({
  content: "",
  title: "",
  id: "",
  teamId: "",
  status: ""
});

/**
 * 校验规则
 */
const rules = ref({
  title: [{
    required: true, message: "请输入公告标题", trigger: ["blur", "change"]
  }],
  status: [{
    required: true, message: "请选择状态", trigger: ["blur", "change"]
  }]
});


const load = () => {
  loading.value = true;
  getPublicNoticeInfoApi({
    id: model.value.id
  }).then((res: any) => {
    const {data} = res;
    model.value.title = data.title;
    model.value.status = data.status;
    model.value.content = data.notice;
  }).finally(() => {
    loading.value = false;
  });
}

/**
 * @description 保存公告
 */
const submit = () => {
  formRef.value.validate((valid: boolean) => {
    if (valid) {
      savePublicNoticeApi(model.value).then(() => {
        Notice.success("保存成功");
        router.go(-1);
      });
    }
  });

}


onMounted(() => {
  const {query, params} = route;
  if (params.id !== "add") {
    model.value.id = params.id.toString();
    load();
  }
  if (query.teamId) {
    model.value.teamId = query.teamId.toString();
  }
});
</script>

<style scoped>

</style>