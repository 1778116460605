<template>
  <h-container isBack isTable title="公告列表">
    <template #extra-header>
      <div class="flex-1 text-align-right">
        <el-button type="primary" @click="create">创建公告</el-button>
      </div>
    </template>
    <div>
      <el-form ref="formRef" :model="model" :inline="true" label-width="auto">
        <el-form-item label="标题" prop="name">
          <el-input v-model="model.title" placeholder="请输入标题"/>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="model.status" placeholder="请选择状态" style="width: 240px">
            <h-dict dictKey="status_type"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="load">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="loading" :data="list" stripe style="width: 100%">
      <el-table-column prop="id" label="编号" header-align="center" align="center" width="100" />
      <el-table-column prop="title" header-align="center" align="center" label="标题" width="180" />
      <el-table-column prop="createtime" header-align="center" align="center" label="创建时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.createtime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" header-align="center" align="center" label="修改时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.updatetime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="status" header-align="center" align="center" label="状态">
        <template #default="scope">
          <el-tag v-if="scope.row.status == 'normal'" :type="scope.row.status == 'normal' ? 'success' : 'danger'"><h-dict mode="text" dictKey="status_type" v-model="scope.row.status"/></el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" header-align="center" fixed="right" width="260">
        <template #default="scope">
          <el-button type="primary" @click="toUpdatePage(scope.row)" link>修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="h-pagination">
        <h-pagination @change="paginationChangeEvent" :current-page="page" :total="totalRow" />
      </div>
    </template>
  </h-container>
</template>

<script lang="ts" setup>
import {ref, onMounted} from "vue";
import {useRouter, useRoute} from "vue-router";
import {useApp} from "@hview/hview-vue3/packages/hooks/use-app";
import {useTimeFormat} from "@hview/hview-vue3/packages";
import {usePagination} from "@hview/hview-vue3/packages";
import {getPublicNoticeListApi} from "@/api/kh";
const {updatePage, getPagination, totalRow, update, reload, page} = usePagination();
const {timeFormat} = useTimeFormat();
const {loading} = useApp();
const router = useRouter();
const route = useRoute();
const formRef = ref();
/**
 * @description 页码改变事件
 */
const paginationChangeEvent = (value: number) => {
  update(value, load);
}
const list = ref([]);
const model = ref({
  teamId: "",
  name: "",
  status: ""
});
/**
 * @description 重置
 */
const resetForm = () => {
  formRef.value.resetFields();
  reload(load);
}

const toUpdatePage = (data: any) => {
  router.push(`/team/announcement/${data.id}?teamId=${model.value.teamId}`);
}

const create = () => {
  router.push(`/team/announcement/add?teamId=${model.value.teamId}`);
}

/**
 * @description 加载数据
 */
const load = () => {
  loading.value = true;
  getPublicNoticeListApi({...model.value, ...getPagination()}).then((res: any) => {
    const data = res.data;
    updatePage(data);
    list.value = data.data;
  }).finally(() => {
    loading.value = false;
  });
}

onMounted(() => {

  const {query} = route;
  if (query.id) {
    model.value.teamId = query.id.toString();
    load();
  }
});
</script>

<style scoped>

</style>