/**
 * @description 成人全营养项目接口
 */

import Http from "@hview/hview-http";

/**
 * @description 获取问卷列表
 */
export const getTestListApi = () => Http.post("/addons/mental/test/getlist", {limit: 999999});
