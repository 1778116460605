<template>
  <div class="share-info">
    <div class="h-item-title">分享信息</div>
    <div class="share-info p-b-30">
      <el-form ref="formRef" :model="model" :rules="rules" :label-width="130">
        <el-form-item label="是否配置分享">
          <el-radio-group v-model="uiModel.isConfigShare">
            <el-radio value="0" size="large">不需要</el-radio>
            <el-radio value="1" size="large">需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="uiModel.isConfigShare == '1'">
          <el-form-item label="分享标题" prop="title">
            <el-input v-model="model.title" placeholder="请输入分享标题"/>
          </el-form-item>
          <el-form-item label="分享链接" prop="path">
            <el-input v-model="model.path" placeholder="请输入分享链接"/>
          </el-form-item>
          <el-form-item label="分享图片" prop="imageUrl">
            <h-upload v-model="model.imageUrl">
              <template #default>
                <el-button :icon="UploadFilled">上传图标</el-button>
              </template>
              <template #file="scope">
                <img :src="scope.file.fullurl" class="w-50 h-50"/>
              </template>
            </h-upload>
          </el-form-item>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts" setup name="ShareInfo">
  import {ref, defineExpose} from "vue";
  import { UploadFilled } from '@element-plus/icons-vue';
  const uiModel = ref({
    // 是否配置分享
    isConfigShare: "0"
  });

  const formRef = ref();

  /**
   * 分享配置
   */
  const model = ref({
    title: "",
    path: "",
    imageUrl: ""
  });


  /**
   * 校验规则
   */
  const rules = ref({
    title: [{
      required: true, message: "请输入分享标题", trigger: ["blur", "change"]
    }],
    path: [{
      required: true, message: "请输入分享链接", trigger: ["blur", "change"]
    }],
    imageUrl: [{
      required: true, message: "请上传分享图片", trigger: ["blur", "change"]
    }]
  });


  /**
   * @description 表单校验
   */
  const validate = () => {
    return new Promise((resolve, reject) => {
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          resolve(valid);
        } else {
          reject(valid);
        }
      })
    });
  }

  /**
   * @description 获取表单数据
   */
  const getFormData = () => {
    if (uiModel.value.isConfigShare == "0") {
      return {};
    }
    return {share_info_json: JSON.stringify(model.value)};
  }

  /**
   * @description 设置数据
   */
  const setData = (data: any) => {
    if (data) {
      uiModel.value.isConfigShare = "1";
      model.value = data;
    }
  }


  defineExpose({
    validate,
    getFormData,
    setData
  });
</script>

<style scoped>

</style>