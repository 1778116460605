import Http, {RequestParams} from "@hview/hview-http";

/**
 * @description 获取用户信息
 * @param params
 */
export const getMemberDataApi = (params: RequestParams) => Http.post("/api/v2/doctor/member/getMemberData", params);

/**
 * @description 获取表单列表
 * @param params
 */
export const getFormListApi = (params: RequestParams) => Http.post("/api/v2/doctor/Customform/getFormList", params);

/**
 * @description 创建表单
 * @param params
 */
export const submitFormApi = (params: RequestParams) => Http.post("/api/v2/doctor/Customform/submitForm", params);

/**
 * @description 修改表单
 * @param params
 */
export const updateFormApi = (params: RequestParams) => Http.post("/api/v2/doctor/Customform/updateForm", params);


/**
 * @description 获取表单信息
 * @param params
 */
export const getFormInfoApi = (params: RequestParams) => Http.post("/api/v2/doctor/Customform/getFormInfo", params);

/**
 * @description 通过resultId获取填写结果
 * @param params
 */
export const getFormResultApi = (params: RequestParams) => Http.post("/api/v2/doctor/Customform/getFormResult", params);

/**
 * @description 通过fromid查询当前表单下填写的数据
 * @param params
 */
export const getFromUserResultListApi = (params: RequestParams) => Http.post("/api/v2/doctor/Customform/getFromUserResultList", params);

/**
 * @description 提交用户表单
 * @param params
 */
export const submitUserResultApi = (params: RequestParams, formId: number) => {
    return Http.post(`/api/v2/doctor/Customform/submitUserResult?id=${formId}`, params);
}

/**
 * @description 删除表单
 * @param params
 */
export const deleteFormApi = (params: RequestParams) => Http.post("/api/v2/doctor/Customform/deleteForm", params);

/**
 * @description 获取文章列表
 * @param params
 */
export const getArticleListApi = (params: RequestParams) => Http.post("/api/v2/doctor/article/getArticleList", params);

/**
 * @description 创建文章
 * @param params
 */
export const addArticleApi = (params: RequestParams) => Http.post("/api/v2/doctor/article/addArticle", params);

/**
 * @description 获取文章
 * @param params
 */
export const getArticleInfoApi = (params: RequestParams) => Http.post("/api/v2/doctor/article/getArticleInfo", params);

/**
 * @description 修改文章
 * @param params
 */
export const updateArticleApi = (params: RequestParams) => Http.post("/api/v2/doctor/article/updateArticle", params);

/**
 * @description 删除文章
 * @param params
 */
export const deleteArticleApi = (params: RequestParams) => Http.post("/api/v2/doctor/article/deleteArticle", params);

/**
 * @description 将用户移除社区
 * @param params teamId: 战队id  userId：用户id
 */
export const removeMemberToTeamApi = (params: RequestParams) => Http.post("/api/v2/doctor/member/removeMemberToTeam", params);
