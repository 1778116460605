<template>
  <div class="h-container" :class="{'frameless': frameless}">
    <div class="h-container__header" v-if="($slots.header || isBack || title) && !frameless">
      <slot v-if="$slots.header" name="header"/>
      <template v-else>
        <div v-if="isBack" class="h-container__header_back" @click="back">
          <el-button type="primary" link><span class="iconfont icon-zuojiantou_huaban"></span>返回</el-button>
        </div>
        <div v-if="isBack && title" class="h-container__header_line"></div>
        <div v-if="title" class="h-container__header_title" :style="!isBack ? 'font-weight: 600' : ''">{{ title }}</div>
        <slot name="extra-header"/>
      </template>
    </div>
    <div class="h-container__content">
      <!-- 因el-table在flex布局中，table表格宽度有问题，特殊针对于el-table特殊处理 -->
      <div class="h-container__content_table-wrapper" v-if="isTable">
        <slot/>
      </div>
      <slot v-else/>
    </div>
    <div class="h-container__footer" v-if="$slots.footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import {defineProps, useSlots, withDefaults} from "vue";
  import { useRouter } from "vue-router";
  const router = useRouter();
  const solts = useSlots();

  interface Props {
    // 标题
    title?: string;
    // 是否带有返回
    isBack?: boolean;
    // 是否是包含table表格，默认false（此配置为了解决element-ui中table宽度问题）
    isTable?: boolean;
    // 是否是无框模式，默认false
    frameless?: boolean;
    // 是否是自定义返回按钮
    backFn?: any;

  }

  const props = withDefaults(defineProps<Props>(), {
    isBack: false,
    isTable: false,
    frameless: false
  });

  /**
   * @description 返回
   */
  const back = (): void => {
    if (props.backFn && props.backFn instanceof Function) {
      props.backFn();
    } else {
      router.back();
    }
  }

</script>

<style lang="scss">
.h-container {
  height: 100%;
  background: #FFFFFF;
  border-top-left-radius: 10px;
  display: flex;
  flex-direction: column;
  &.frameless {
    background: none;
    .h-container__content {
      padding: 0;
      &_table-wrapper {
        width: 100%;
        height: 100%;
      }

    }
  }
  &__header {
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #EEF0F4;
    padding: 0px 14px;
    box-sizing: border-box;
    &_title {
      font-weight: 400;
      font-size: 16px;
      color: #24262A;
      font-family: PingFangSC-S0pxibold;
      letter-spacing: 0;
      line-height: 16px;
    }
    &_back {
      .el-button {
        font-size: 16px;
      }
    }
    &_line {
      width: 1px;
      height: 16px;
      background: #CBCED8;
      margin: 0 12px;
    }
  }
  &__content {
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    &_table-wrapper{
      position: absolute;
      width: calc(100% - 20px);
      height: calc(100% - 10px);
    }
  }
  &__footer {
    height: 72px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    box-shadow: 0 -4px 8px 0 rgba(203,206,216,0.16);
  }
}
</style>